import {PollStatus} from '../db-models/session';
import {SessionState} from '../types/session';
import {SharedLinkTargetType, ShareMethod} from '../db-models/links';
import {encodeAsJson} from '../utilities/object-helpers';

export enum EventName {
  UserActivenessInSessionState = 'UserActivenessInSessionState',
  UserJoined = 'UserJoined',
  UserLeft = 'UserLeft',
  UserSetSessionReminder = 'UserSetSessionReminder',
  UserAddedSessionToCalendar = 'UserAddedSessionToCalendar',
  CartOpened = 'CartOpened',
  CartClosed = 'CartClosed',
  CartClosedByUser = 'CartClosedByUser',
  CartClosedBySystem = 'CartClosedBySystem',
  ItemViewed = 'ItemViewed',
  ItemAddedToCart = 'ItemAddedToCart',
  ItemRemovedFromCart = 'ItemRemovedFromCart',
  ItemRemovedFromCartByUser = 'ItemRemovedFromCartByUser',
  ItemRemovedFromCartBySystem = 'ItemRemovedFromCartBySystem',
  Checkout = 'Checkout',
  AutoCheckout = 'AutoCheckout',
  PurchaseCompleted = 'PurchaseCompleted',
  PollActivated = 'PollActivated',
  PollDeactivated = 'PollDeactivated',
  PollDeleted = 'PollDeleted',
  PollVoted = 'PollVoted',
  PollViewed = 'PollViewed',
  SessionLiked = 'SessionLiked',
  ChatMessageSent = 'ChatMessageSent',
  ShareLinkRequested = 'ShareLinkRequested',
  ShareLinkClicked = 'ShareLinkClicked',
  HostStartedRecording = 'HostStartedRecording',
  HostStoppedRecording = 'HostStoppedRecording',
}

export enum ItemViewSource {
  ItemsList = 'ItemsList',
  FeaturedItem = 'FeaturedItem',
  Poll = 'Poll',
  SharedLink = 'SharedLink',
}

export const ALLOWED_FROM_CLIENT = [
  EventName.UserActivenessInSessionState,
  EventName.UserAddedSessionToCalendar,
  EventName.ItemViewed,
  EventName.PollActivated,
  EventName.PollDeactivated,
  EventName.PollDeleted,
  EventName.PollViewed,
  EventName.ShareLinkRequested,
  EventName.ShareLinkClicked,
];

export enum UserRole {
  Admin = 'admin',
  StoreManager = 'storeManager',
  Host = 'host',
  User = 'user',
  Guest = 'guest',
}

export enum ReminderChannel {
  Email = 'email',
  Sms = 'sms',
}

export interface BasicEvent {
  name: EventName;
  /**
   * ISO formatted date time
   */
  timeStamp: string;
}

export interface UserEventData {
  userId: string;
  role?: UserRole; // undefined if it can not be detected (e.g. session already deleted)
}

export interface StoreEventData {
  storeId: string;
}

export interface SessionEventData {
  sessionId: string;
  sessionState?: SessionState; // undefined if it can not be detected (e.g. session already deleted)
}

export type UserActivenessInSessionStateEventData = {
  userActive: boolean;
};
export type UserJoinedEventData = {
  ipAddress: string;
  /**
   * ISO formatted date time
   */
  seatCreateTime: string;
  /**
   * ISO formatted date time
   */
  seatLastHeartBeat: string;
  seatId: string;
  seatNumber: number | null;
  seatIsReleased: boolean;
};

export type UserLeftEventData = {
  ipAddress: string;
  /**
   * ISO formatted date time
   */
  seatCreateTime: string;
  /**
   * ISO formatted date time
   */
  seatLastHeartBeat: string;
  seatId: string;
  seatNumber: number | null;
  seatIsReleased: boolean;
  secondsOfView: number;
};

export type UserSetSessionReminderEventData = {
  reminderChannels: ReminderChannel[];
};

export type UserAddedSessionToCalendarEventData = {};

export type CartOpenedEventData = {
  cartId: string;
};

export type CartClosedEventData = {
  cartId: string;
};

export type Item = {
  productId: string;
  variantId?: string;
  price?: number;
  count?: number;
};
export type ItemViewedEventData = {
  items: Pick<Item, 'productId'>[];
  itemViewSource: ItemViewSource;
};

export type ItemsChangedInCartEventData = {
  cartId: string;
  items: Required<Item>[];
  cartItemsCountDelta: number;
  cartItemsValueDelta: number;
};

export type ItemAddedToCartEventData = ItemsChangedInCartEventData;

export type ItemRemovedFromCartEventData = ItemsChangedInCartEventData;

export type CheckoutEventData = {
  cartId: string;
  items: Required<Item>[];
  refererUserId: string | null;
  auxData: string;
};

/**
 * To build correct event data 'data.items.count' value
 * is expected to be positive if items were added to cart, and negative if removed.
 */
export function buildItemsChangedInCartData(
  data: Omit<ItemsChangedInCartEventData, 'cartItemsCountDelta' | 'cartItemsValueDelta'>
): ItemAddedToCartEventData | ItemRemovedFromCartEventData {
  return enrichWithCartItemDeltasAndCorrectItemsCountSign(data);
}

export function buildCheckoutEventData(
  data: Omit<CheckoutEventData, 'auxData'> & {
    auxData: object;
  }
): CheckoutEventData {
  return {
    ...data,
    auxData: JSON.stringify(encodeAsJson(data.auxData)),
  };
}

function enrichWithCartItemDeltasAndCorrectItemsCountSign<T extends {items: Required<Item>[]}>(
  data: T,
  negate: boolean = false
): T & {cartItemsCountDelta: number; cartItemsValueDelta: number} {
  let cartItemsCountDelta: number = 0;
  let cartItemsValueDelta: number = 0;

  data.items.forEach((item) => {
    cartItemsCountDelta += item.count;
    cartItemsValueDelta += item.count * item.price;
    item.count = Math.abs(item.count); // Absolute value for item.count is expected in the event
  });
  if (negate) {
    cartItemsCountDelta = -cartItemsCountDelta;
    cartItemsValueDelta = -cartItemsValueDelta;
  }
  return {
    ...data,
    cartItemsCountDelta,
    cartItemsValueDelta,
  };
}

export type PurchaseCompletedEventData = {
  cartId: string;
  items: Required<Item>[];
  subTotal: number;
  discountsTotal: number;
  taxesTotal: number;
  shippingTotal: number;
  fullTotal: number;
};

export type PollActivatedEventData = {
  pollId: string;
};

export type PollDeactivatedEventData = {
  pollId: string;
};

export type PollDeletedEventData = {
  pollId: string;
};

export type PollVotedEventData = {
  pollId: string;
  pollAnswer: string;
};

export type PollViewedEventData = {
  pollId: string;
  pollStatus: PollStatus;
};

export type SessionLikedEventData = {};

export type ChatMessageSentEventData = {};

export type ShareLinkRequestedEventData = {
  shareTarget: SharedLinkTargetType;
  shareLinkId: string;
  shareMethod: ShareMethod;
  shareProductId?: string;
};

export enum ReferralSource {
  Facebook = 'Facebook',
  Instagram = 'Instagram',
  TikTok = 'TikTok',
  Twitter = 'Twitter',
  Other = 'Other',
}

export type ShareLinkClickedEventData = {
  shareTarget: SharedLinkTargetType;
  shareLinkId: string;
  refererUserId: string;
  referralSource: ReferralSource;
  shareProductId?: string;
};

export type SessionEventDataTypes = {
  [EventName.UserActivenessInSessionState]: UserActivenessInSessionStateEventData;
  [EventName.UserJoined]: UserJoinedEventData;
  [EventName.UserLeft]: UserLeftEventData;
  [EventName.UserSetSessionReminder]: UserSetSessionReminderEventData;
  [EventName.UserAddedSessionToCalendar]: UserAddedSessionToCalendarEventData;
  [EventName.CartOpened]: CartOpenedEventData;
  [EventName.CartClosed]: CartClosedEventData;
  [EventName.CartClosedByUser]: CartClosedEventData;
  [EventName.CartClosedBySystem]: CartClosedEventData;
  [EventName.CartClosed]: CartClosedEventData;
  [EventName.ItemViewed]: ItemViewedEventData;
  [EventName.ItemAddedToCart]: ItemAddedToCartEventData;
  [EventName.ItemRemovedFromCart]: ItemRemovedFromCartEventData;
  [EventName.ItemRemovedFromCartByUser]: ItemRemovedFromCartEventData;
  [EventName.ItemRemovedFromCartBySystem]: ItemRemovedFromCartEventData;
  [EventName.Checkout]: CheckoutEventData;
  [EventName.AutoCheckout]: CheckoutEventData;
  [EventName.PurchaseCompleted]: PurchaseCompletedEventData;
  [EventName.PollActivated]: PollActivatedEventData;
  [EventName.PollDeactivated]: PollDeactivatedEventData;
  [EventName.PollDeleted]: PollDeletedEventData;
  [EventName.PollVoted]: PollVotedEventData;
  [EventName.PollViewed]: PollViewedEventData;
  [EventName.SessionLiked]: SessionLikedEventData;
  [EventName.ChatMessageSent]: ChatMessageSentEventData;
  [EventName.ShareLinkRequested]: Omit<ShareLinkRequestedEventData, 'shareProductId'>;
  [EventName.ShareLinkClicked]: Omit<ShareLinkClickedEventData, 'shareProductId'>;
  [EventName.HostStartedRecording]: BasicEvent;
  [EventName.HostStoppedRecording]: BasicEvent;
};

export type StoreEventDataTypes = {
  [EventName.ShareLinkRequested]: Omit<ShareLinkRequestedEventData, 'shareProductId'>;
  [EventName.ShareLinkClicked]: Omit<ShareLinkClickedEventData, 'shareProductId'>;
};

export type StoreEventTypes<T extends keyof StoreEventDataTypes> = BasicEvent &
  UserEventData &
  StoreEventData &
  StoreEventDataTypes[T];

export type SessionEventTypes<T extends keyof SessionEventDataTypes> = BasicEvent &
  UserEventData &
  StoreEventData &
  SessionEventData &
  SessionEventDataTypes[T];

export type AnyEvent<T = object> = BasicEvent &
  Partial<UserEventData> &
  Partial<StoreEventData> &
  Partial<SessionEventData> &
  T;

export type AnyClientEvent<T = object> = Omit<BasicEvent, 'timeStamp'> &
  Partial<StoreEventData> &
  Partial<Omit<SessionEventData, 'sessionState'>> &
  T;
